.participants-list-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .settings {
    display: flex;
    flex-direction: column;
    align-items: center;  /* Align items to the right */
    justify-content: center;
    /* padding: 10px; */
    margin-top: 20px;
    background: transparent; /* Example background color */
    /* border: 1px solid var(--text-white);  */
    border-radius: 10px; /* Rounded corners for the border */
    /* flex-basis: 33.3%;  */
    margin-left: auto; /* Automatically pushes block to the right */
    font-size: 18px;
    margin-right: 5px;
}

.settings-title {
  font-size: 20px;
  background-color: var(--card-dark); /* Your preferred background color */
  color: #fff; /* Text color */
  width: 100%; /* Make the title stretch across the full width */
  padding: 10px 15px; /* Padding for better spacing */
  box-sizing: border-box; /* Includes padding and border in the element's width */
  margin-bottom: 10px; /* Space between title and fields */
  text-align: center; /* Center the text, if desired */
  border-top-left-radius: 10px;  /* Rounded top-left corner */
  border-top-right-radius: 10px; /* Rounded top-right corner */
}

.settings-fields {
  display: flex;
  width: 100%;
  justify-content: space-around; /* Distribute space around items */
  align-items: center; /* Vertically center items */
}

 .dropdown-container {
  flex: 1; /* Allow each container to grow evenly */
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
}


.capture-icon {
  font-size: 32px;
  color: var(--text-white); 
  padding-left: 10px;
  padding-bottom: 2px;
  transition: transform 0.3s ease; /* Smooth transition for the transform property */
}

.capture-icon:hover {
  color: var(--green);
  cursor: pointer;
  transform: scale(1.2); /* Increase the size of the icon by 20% on hover */
}



  .dropdown-container {
    display: flex;
    justify-content: flex-end; /* Aligns the dropdown to the right */
    /* margin-bottom: 10px;  */
    padding-top: 0px;
    padding-right: 10px;
    align-items: center; /* Aligns label and select vertically */
}

.dropdown-container select {
    background-color: transparent;
    font-size: 18px; /* Adjust the font size */
    color: var(--text-white); /* Adjust the color */
}

.dropdown-container:hover select {
    background-color: var(--green);
    color: var(--card-dark);
    cursor: pointer;
  }


  .participants-list {
    overflow-y: auto;
    padding-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    /* padding: 16px; */
  }

  /* Hide the leaderboard on mobile devices */
@media (max-width: 600px) {
  .participants-list {
    gap:10px;
  }
}


@media (max-width: 500px) {
  .participants-list-container {
    padding-left: 2px;
    padding-right: 2px;
  }
}
@media (min-width: 1000px) {
  .participants-list {
      grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  .participants-list {
      grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1350px) {
  .participants-list {
      grid-template-columns: repeat(4, 1fr);
  }
}