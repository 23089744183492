.full-container {
    background-image: linear-gradient(var(--back-light), var(--back-dark));
    min-height: 100vh;
}

.session-results {
    text-align: center;
    background-color: transparent;
    padding: 10px 10px;
    margin: auto;
}

.session-title-results{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  /* Aligns children (title and date) in the center horizontally */
    gap: 20px;
    font-size: 24px;
    padding-top: 20px;
    padding-bottom: 40px;
}

.session-title-results-text {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: bold;
    color: var(--text-white);
}


.podium {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 5px;
    margin-bottom: 30px;
}

.podium-place {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    flex: 1; /* Allow flex items to grow and shrink equally */
    max-width: 200px; /* Set a max width to prevent excessive stretching */
    color: var(--text-white);
    text-align: center;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowed content */
    text-overflow: ellipsis; /* Display ellipsis for overflowed content */
    font-weight: bold;
}

.podium-username-wrapper {
    display: block;
    width: 100%;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowed content */
    text-overflow: ellipsis; /* Display ellipsis for overflowed content */
    text-align: center; /* Center text */
}


.podium-username {
    font-size: 16px;
    margin-top: 0px;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowed content */
    text-overflow: ellipsis; /* Display ellipsis for overflowed content */
}

.podium-pushups {
    font-size: 16px;
    color: var(--text-white);
    padding-top: 5px;
    font-size: 1.5em;
}

.podium-place-1 {
    order: 2; /* Center */
    transform: translateY(-20px); /* Slightly higher */
}


.podium-place-1 .podium-pushups {
    font-size: 2em; /* Larger font size for the winner's pushup count */
}

.podium-place-2 {
    order: 1; /* Left */
}

.podium-place-3 {
    order: 3; /* Right */
}

.medal {
    font-size: 3em; /* Default medal size */
}

.medal-1 {
    font-size: 4em; /* Bigger medal for the winner */
}


.participants-results-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 0px;
}

.participant-result {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    color: var(--text-white);
    background-color: var(--card-dark);
}

.feedback-socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 40px;
    margin-bottom: 20px;
    flex-wrap: nowrap;
}

.feedback-link {
    margin-top: 0px;
    padding-top: 0px;  /* Removed extra padding that might cause misalignment */
    margin-bottom: 0px;
}

.feedback-link a {
    color: var(--text-white);
    text-decoration: none;
    font-size: 18px;
    padding: 10px 5px;
    border: 2px solid var(--green);
    border-radius: 5px;
    background-color: var(--green);
    display:inline-flex;
}

.feedback-link a:hover {
    background-color: var(--text-white);
    color: var(--green);
}

.join-us-text {
    margin: 0;
    font-size: 18px;
    color: var(--text-white);
}

.social-link {
    display: flex;
    align-items: center;
}

.social-icon {
    width: 32px;
    height: 32px;
}



.capture-button-results {
    border: 1px solid white; 
    background: var(--card-dark);
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
    /* padding-left: 5px;
    padding-right: 5px; */
    text-align: flex;
    color: var(--text-white);
    font-size: 18px;
    width: 40px;
    align-self: flex-end;  /* Ensures button aligns itself in center if flex items have different heights */
}

.capture-button-results:hover {
    background-color: var(--text-white);
    color: var(--card-dark);
}

.capture-icon-results {
  font-size: 24px;
  color: var(--text-white); 
}



@media (min-width: 768px) {
    .session-results {
        width: 60%;
    }
}

@media (max-width: 1400px) {
    .feedback-link a {
        /* white-space: normal;
        min-height: 100px;  */
        font-size: 16px;
    }
    .join-us-text{
        font-size: 16px;
    }
    .feedback-socials{
        gap: 5px;
    }
    
}
