
.app-leaderboard {
  position: relative;
  padding-top: 40px;
}

.app-leaderboard::before {
  content: '';
  position: absolute;
  top: 60px;
  bottom: 0;
  right: 0;
  width: 2px;
  background-color: var(--text-white);
}

.leaderboard-list{
  padding: 0 10px;
}

.leaderboard-title{
  background-color: transparent;
  color: var(--text-white);
  display: flex; 
  align-items: flex-end; 
  justify-content: center; 
  margin-top: 60px;
  padding-bottom: 82px;
  font-size: 32px;
  text-align: center;
  width: 100%;
  font-weight: bold;
  margin: 0px;
}

.leaderboard-item {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align all children to the bottom */
  padding: 10px 10px;
  margin-bottom: 10px;
  background: var(--card-dark);
  border-radius: 5px;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  /* box-shadow: 0px 0px 3px 1px var(--text-white); */
}

.leaderboard-item:hover {
  transform: scale(1.02);
  background-color: #e0e0e0;
}

.leaderboard-item-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.leaderboard-item-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}
.leaderboard-item-exit {
  opacity: 1;
  transform: translateY(0);
}
.leaderboard-item-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 500ms, transform 500ms;
}

.leaderboard-item:hover .rank,
.leaderboard-item:hover .username,
.leaderboard-item:hover .pushups {
  color: var(--card-dark);
}


.leaderboard-item .rank {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-white);
  flex: 0 0 30px;
}

.leaderboard-item .username {
  font-size: 16px;
  color: var(--text-white);
  flex: 1;
}

.leaderboard-item .pushups {
  font-size: 16px;
  color: var(--text-white);
  flex: 0 0 22px;
  text-align: right;
}

.leaderboard-item.selected {
  outline: 4px solid var(--green); /* Outline instead of border */
}

@media (max-width: 950px) {
  .app-leaderboard {
    display: none;
  }
}