.help-page-container {
  background-image: linear-gradient(var(--back-light),var(--back-dark)); /* Gradient from #313E46 to #0F1214 */
  min-height: 100vh;  

  }
    
  .setup-section {
    margin-bottom: 24px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .setup-section h2 {
    color: var(--text-white);
    font-size: 20px;
    margin-bottom: 8px;
  }
  
  .setup-section p,
  .setup-section ol {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
    color: var(--text-white);

  }
  
  .setup-section ol {
    padding-left: 20px;
  }
  
  .setup-section ol li {
    margin-bottom: 8px;
  }
  
  .setup-section a {
    color: #007bff;
    text-decoration: none;
  }
  
  .setup-section a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .back-link {
      display: block;
      text-align: center;
      margin-bottom: 24px;
    }
  
    /* .help-page-container {
      padding: 12px;
    } */
  
    .setup-section h2 {
      font-size: 18px;
    }
  
    .setup-section p,
    .setup-section ol {
      font-size: 14px;
    }
  
    .setup-section ol li {
      margin-bottom: 12px;
    }

    .setup-section{
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  