/* src/components/Footer.css */
.footer {
    background-color: var(--text-white); /* Change to your desired background color */
    color: var(--text-dark);
    padding-top: 20px;
    margin-top: 40px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.join-us-footer-text{
  font-size: 18px;
}
  
.footer-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
}
        
  .app-store-link img {
    width: 150px; /* Adjust the size as needed */
    /* margin-bottom: 20px; */
    padding-left: 20px;
    height: 50px;
  }
  
  .social-media-icons a {
    margin: 0 10px;
  }
  
  .social-media-icons img {
    width: 40px; /* Adjust the size as needed */
  }
  
  @media (max-width: 600px) {
    .footer-content {
      padding-right:10px;
    }
    .app-store-link img {
      padding-left: 10px;
    }
    .social-media-icon img {
      width: 80px; /* Adjust the size as needed */
      /* margin-bottom: 20px; */
      padding-right: 10px;
    }
  
  }

  @media (max-width: 400px) {
    .footer-content {
      padding-right:10px;
    }
    .app-store-link img {
      padding-left: 10px;
      width: 120px; /* Adjust the size as needed */
    }
    .social-media-icon img {
      width: 60px; /* Adjust the size as needed */
      /* margin-bottom: 20px; */
      padding-right: 5px;
    }
  
  }
  