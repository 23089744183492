.participant-card {
  position: relative;
  /* border: 1px solid white; */
  /* border-radius: 5px; */
  padding-left: 0px;
  margin-bottom: 0px;
  /* background-color: white; */
  transition: border-color 0.3s ease;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 1px var(--text-white);
}

.participant-card.first {
  box-shadow: 0 0 10px 4px rgba(255, 215, 0, 0.75); /* Gold glow */
}

.participant-card.second {
  box-shadow: 0 0 10px 4px rgba(192, 192, 192, 0.75); /* Silver glow */
}

.participant-card.third {
  box-shadow: 0 0 10px 4px rgba(205, 127, 50, 0.75); /* Bronze glow */
}

.participant-card.selected {
  outline: 5px solid var(--green); /* Outline instead of border */
  /* outline-offset: -10px; Optional: to shift the outline inward */
}

.tooltip {
  position: absolute;
  top: 50px;
  left: 5px;
  padding: 5px;
  background-color: var(--text-white);
  color: var(--text-dark);
  border-radius: 5px;
  font-size: 14px;
  z-index: 20;
  white-space: nowrap;
  display: none; /* Hide the tooltip by default */
}

.autoplay-warning {
  position: absolute;
  top: 50%; /* Center vertically in the video container */
  left: 50%; /* Center horizontally in the video container */
  transform: translate(-50%, -50%); /* Center the text */
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  color: white; /* White text for contrast */
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
  z-index: 30; /* High z-index to ensure it's visible */
  text-shadow: 0 0 8px #000; /* Optional: text shadow for better readability */
}


.participant-card:hover .tooltip {
  display: block; /* Show the tooltip on hover */
}

  .participant-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    color:var(--card-dark);
    border: 1px solid white; /* Default border */
    padding-bottom: 10px;
    padding-top: 10px;
    border-top-left-radius: 10px; /* Top left corner radius */
    border-top-right-radius: 10px; /* Top right corner radius */

    box-sizing: border-box; /* Include padding and border in the element's total width and height */

    /* padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;   */
  }

  .participant-card-header h2 {
    font-size: 18px;
    margin: 0;
    color: #333;
    flex: 1;
  }
  

.participant-card-header .username
 {
  font-size: 16px;
  color: var(--text-dark);
  margin: 0 4px;
  flex: 0;
  white-space: nowrap;      /* Prevent text from wrapping */
  color: var(--text-white);
}

.participant-card-header .pushups{
  font-size: 16px;
  color: var(--text-dark);
  margin: 0 4px;
  flex: 0;
  white-space: nowrap;      /* Prevent text from wrapping */
  color: var(--text-white);
  padding-right: 5px;
}


.participant-card-header .rank
 {
  font-size: 18px;
  color: var(--text-white);
  margin: 0 4px;
  flex: 0;
  font-weight: bold;
  padding-left: 5px;
}

  
  .video-container {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; */
    width: 100%;
    /* height: 200px; */
    position: relative;
    overflow: hidden;
    /* margin-top: 12px; */
    padding-bottom: 56.25%;
    border-bottom-left-radius: 10px; /* Bottom left corner radius */
    border-bottom-right-radius: 10px; /* Bottom right corner radius */  
  }

  .video-container > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: white; */
  }
/* 
  .video-feed > div {
    background-color: white !important;
  } */
  

  .agora_video_player{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--card-dark);
    color: #fff;
    font-size: 16px;
    border-bottom-left-radius: 10px; /* Bottom left corner radius */
    border-bottom-right-radius: 10px; /* Bottom right corner radius */  
    text-align: center;
  }

  
  @media (max-width: 390px) {
    .participant-card {
      width:174px ;
    }
  }
  