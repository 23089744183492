.header {
    display: flex;
    justify-content: flex-start; /* Align items to the left */
    align-items: center;
    padding: 0px 10px;
    padding-top: 10px;
    background-color: transparent;
    min-height: 80px;
    transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.header .home-link, 
.header .about-link, 
.header .download-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and size */
    position: relative; /* For tooltip positioning */
}

.header .about-link{
    padding-left: 40px;
}

.header .home-link .logo-image,
.header .download-link .download-image {
    height: 80px;
    transition: transform 0.3s ease, filter 0.3s ease; /* Smooth transition for size and filter */
}

.header .home-link:hover .logo-image,
.header .download-link:hover .download-image {
    transform: scale(1.1); /* Enlarge logo on hover */
}

.header .home-link:hover .logo-image:hover {
    cursor: pointer;
}

.header .about-link {
    color: var(--text-white);
    font-size: 24px;
    font-weight: bold;
    transition: color 0.3s ease; /* Smooth transition for color */
}

.header .about-link:hover {
    text-decoration: underline; /* Underline on hover */
    cursor: pointer;
}

.header:hover {
    background-color: var(--text-white); /* Change navbar background color on hover */
}

.header:hover .home-link .logo-image {
    filter: brightness(0)
}

.header:hover .about-link {
    color: var(--back-light);
}

.header .download-link {
    position: relative;
}

.header .download-image.white {
    display: block;
}

.header .download-image.black {
    display: none;
}

.header:hover .download-image.white {
    display: none;
}

.header:hover .download-image.black {
    display: block;
}

.tooltip {
    position: absolute;
    /* bottom: -250%;  */
    left: 50%; /* Center the tooltip */
    transform: translateX(-50%) translateY(-20px);
    background-color: var(--text-white);
    color: var(--card-gray);
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 24px;
    display: none; /* Hide by default */
    margin-bottom: 5px;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    visibility: hidden;
}

.header .about-link:hover .tooltip {
    display: block;
    opacity: 1;
    visibility: visible;
}

.logo-image {
    height: 80px;
}

@media (max-width: 400px) {

    .header {
        justify-content: space-between; /* Revert to space-between for smaller screens */
        padding: 0px 10px; /* Adjust padding for smaller screens */
    }

    .header .about-link {
        font-size: 18px;  
        padding-right: 20px;
        /* padding-left: 10px; */
    }
    .header .home-link .logo-image,
    .header .download-link .download-image {
        height: 48px;  
    }
    .tooltip {
        font-size: 18px;
    }

}



@media (min-width: 400px) and (max-width: 600px) {
    .header .about-link {
        font-size: 18px;  
    }
    .header .home-link .logo-image,
    .header .download-link .download-image {
        height: 60px;  
    }
}
