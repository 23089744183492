.session-container{
  background-image: linear-gradient(var(--back-light),var(--back-dark));
  padding-bottom: 20px;
  min-height: 100vh; 
}

/* 
.full-container{
  background-image: linear-gradient(var(--back-light),var(--back-dark));
  padding-bottom: 20px;
} */



.page-display {
  display: grid;
  grid-template-columns: 250px 1fr 300px; /* 1fr for the participant list and 300px for the leaderboard */
  padding-bottom: 20px;
  background-color: transparent;
}

.capture-area {
  background-image: red ;
}

.leaderboard-container{
  width: 250px; /* Same width as the leaderboard */
}

h1 {
  color: #333;
}


ul {
  padding: 0;
  margin: 0;
  list-style-type: none; 
}


@media (max-width: 950px) {
  .page-display {
    grid-template-columns: 1fr; /* Adjust grid to a single column */
  }
}

