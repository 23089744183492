.home-container {
    /* padding-left: 20px;
    padding-right: 20px; */
    background-image: linear-gradient(var(--back-light),var(--back-dark)); /* Gradient from #313E46 to #0F1214 */
    min-height: 100vh; 
    padding-bottom: 10px;
    width: 100%;
}

/* Main Section Styling */
.main-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* max-width: 1200px; */
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
}

.text-container {
    flex: 1 1 55%; /* Default flex setting allowing shrink and grow */
    padding-right: 20px;
    color: white;
    padding-left: 20px;
}


.text-container h1 {
    font-size: 72px;
    margin-bottom: 20px;
    color: var(--text-white);
}


.text-container p {
    font-size: 28px;
    margin-bottom: 20px;
}

.app-store-link-home img {
    width: 250px; /* Adjust the size as needed */
    /* margin-bottom: 20px; */
    height: 100px;
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and size */
  }

  .app-store-link-home:hover img{
    transform: scale(1.1); /* Enlarge logo on hover */
  }



/* active Session Styling */
.active-session, .upcoming-session {
    flex: 1 1 45%; /* Default flex setting allowing shrink and grow */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:transparent;
    border-radius: 10px;
    margin-bottom: 20px;
    padding-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
}

h3 {
    font-size: 32px;
    color: var(--text-white);
    margin: 0px;
    margin-bottom: 20px;
    text-align: center;
}



.session-status {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 5px;
    color: var(--text-white); 
    /* background-color: var(--text-white); */
    font-size: 16px;
}

.session-status.live-label {
    background-color: red ; /* White text */
    padding-left: 2px;
    padding-right: 2px;
  }
  .session-status.not-live-label {
    background-color: rgba(255, 0, 0, 0.2); /* Red background with reduced opacity */
    padding-left: 2px;
    padding-right: 2px;
  }

.session-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* padding: 20px 0px; */
}

.session-card h2 {
    margin: 0 0 10px 0; /* Add some bottom margin to the title */
    font-size: 18px; /* Adjust font size as needed */
    /* width: 200px; */
    white-space: nowrap;
}




.session-card .counter {
    font-size: 18px; /* Adjust font size as needed */
}

.p-title{
    font-weight: bold;
    margin-top:8px;
    margin-bottom:0px;
    font-size: 16px; /* Slightly smaller font size */
}

.p-answer{
    /* margin: 0px 0;  */
    font-size: 16px; /* Slightly smaller font size */
    margin-top:4px;
    margin-bottom:8px;

}

.session-card {
    background-color: var(--card-dark);
    /* border: 1px solid #ddd; */
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    text-decoration: none;
    color: var(--text-white);
    display: flex;
    flex-direction: column; /* Change to column to stack information */
    /* align-items: start; */
    position: relative;
}

.session-active-card{
    border: 4px solid var(--green); /* Default border */
    box-shadow: 0 0 8px var(--green); /* Optional: add shadow for more emphasis */
    box-sizing: border-box;
    width: 100%;
}

.session-upcoming-card{
    border: 3px solid var(--text-white); /* Green border for emphasis */
    box-shadow: 0 0 8px var(--text-white); /* Optional: add shadow for more emphasis */
    box-sizing: border-box;
    width: 100%;
}


.session-upcoming-counter{
    position: absolute;
    right: 20px; /* Adjust as needed */
    bottom: 20px; /* Adjust as needed */
    display: flex;
    justify-content: flex-end; /* Aligns items to the start of the container */
    align-items: flex-end;
}

.next-sprint {
    border: 3px solid var(--text-white); /* Green border for emphasis */
    box-shadow: 0 0 8px var(--text-white); /* Optional: add shadow for more emphasis */
}

.next-sprint-label {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    color: white; /* White text for contrast */
    font-weight: bold;
    font-size: 24px;
    margin-bottom: -10px; /* Remove space below the label */
}

.winner-container-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.winner-div{
    font-weight: bold;
    font-size: 18px;
}

.winner-user-container-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:10px;
}


.session-card-header {
    display: flex;
    justify-content: flex-start; /* Aligns items to the start of the container */
    align-items: center;
    width: 100%;
}

.session-card-header > :first-child {
    margin-right: 20px; /* Adjust the space as needed */
}


.session-card-header h2{
    margin: 0px;
}

.session-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.chevron {
    font-size: 24px; /* Adjust size as needed */
    color: var(--text-white); /* Light grey color, make it subtle */
    position: absolute;
    right: 20px;
    top:50px;
    font-weight: bold;

}

.join-now {
    position: absolute;
    right: 10px; /* Adjust as needed */
    bottom: 15px; /* Adjust as needed */
    padding: 8px 16px;
    background-color: var(--green); /* Example color */
    color: var(--text-white);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}

.join-now:hover {
    background-color: var(--green); /* Darker shade for hover effect */
}

.sessions-list{
    /* padding-left: 20px;
    padding-right: 20px; */
    width: 50%;
    padding-top: 40px;
    margin: auto;
}

.sessions-section-status{
    font-size:32px;    
    color: var(--text-white);
    margin: 0px;
    padding-bottom: 16px;
    padding-top: 30px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
}



@media (max-width: 800px) {

    .main-section {
        flex-direction: column; /* Stack vertically on smaller screens */
        align-items: center; /* Align items to stretch to container width */
        padding: 0px;

    }

    .text-container {
        order: 1; /* Ensures text container is below the session card */
        padding-right: 0; /* Remove right padding */
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
    }

    .active-session, .upcoming-session {
        order: -1; /* Move the session card above the text container */
        padding-right: 0; /* Remove right padding */
        padding-left: 0px;
        padding-top: 20px;
        width: 80%;
    }

    .text-container h1 {
        font-size: 48px; /* Smaller font size for headers */
        margin-top: 10px;
    }

    .h3 {
        font-size: 48px; /* Smaller font size for headers */
    }

    .sessions-section-status{
        font-size: 24px;
    }

    .text-container p {
        font-size: 20px; /* Adjust paragraph font size for readability */
    }

    .app-store-link-home img {
        width: 200px; /* Adjust image size for smaller screens */
        height: auto; /* Maintain aspect ratio */
    }

    .sessions-list{
        width: 80%;
        padding-top: 0px;
    }

    .session-card {
        padding: 10px;
    }
    .session-card h2, .session-card p {
        font-size: 16px; /* Even smaller font size for very small devices */
    }
    .session-card .counter{
        font-size: 16px; /* Even smaller font size for very small devices */
    }

    .session-status {
        top:5px
    }

    .session-status.live-label {
        background-color: red ; /* White text */
        padding-left: 2px;
        padding-right: 2px;
      }
      .session-status.not-live-label {
        background-color: rgba(255, 0, 0, 0.2); /* Red background with reduced opacity */
        padding-left: 2px;
        padding-right: 2px;
      }
      .join-now {
        bottom: 10px; /* Adjust as needed */
    }
    .session-upcoming-counter{
        bottom: 15px; /* Adjust as needed */
    }

    .session-upcoming-counter .counter-time{
        font-size: 24px;
    }
    
    
}

/* @media (max-width: 400px) {
    .sessions-list{
        padding-left: 10px;
        padding-right: 10px;
    }


} */
