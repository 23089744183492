.counter {
  color: var(--text-white);
  background-color: transparent;
  text-align: center;
  width: 100%;
}

.counter-status {
  font-size: 24px;
  margin-bottom: 4px;
}

.counter-time {
  font-size: 32px; /* Increase the font size of the time */
  font-weight: bold;
}

@media (max-width: 450px) {
  .counter-status{
    margin-bottom: 0px;
  }

}