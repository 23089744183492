.player-button {
    background: none; /* No background */
    border: none; /* No border */
    outline: none; /* Removes the outline to prevent focus ring */
    cursor: pointer; /* Hand cursor on hover */
    color: #fff; /* White icon color, adjust as needed */
    font-size: 32px; /* Icon size, adjust as needed */
    height: 32px;
    align-items: center;
    padding-left: 10px;
    padding-bottom: 5px;
    transition: transform 0.3s ease; /* Smooth transition for the transform property */
  }
  
  .player-button:hover {
    color: var(--green); 
    transform: scale(1.2); /* Increase the size of the icon by 20% on hover */
  }
  
  
  