.chat-component-container {
    width:300px;
    padding-left: 10px;
    padding-right: 10px;
    background: transparent;
  }
  

  .chat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 90px); /* Assuming the header is 60px tall */
    position: relative;
    background: transparent;
    width: 300px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    background: transparent;
    overflow: hidden; /* Ensures the shadow doesn't extend beyond the container */
}

.chat-container::before {
    content: '';
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 0;
    width: 2px;
    background-color: var(--text-white);
}

.chat-title {
    display: flex; 
    align-items: flex-end; 
    justify-content: center; 
    padding-top: 40px;
    padding-bottom: 82px;
    font-size: 32px;
    color: var(--text-white);
    /* padding: 16px; */
    background-color: transparent;
    text-align: center;
    width: 100%;
    font-weight: bold;
  }

.messages-container {
    flex-grow: 1;
    overflow-y: auto;
    /* padding: 10px; */
    background-color: transparent;
    display: flex;
    flex-direction: column-reverse; /* Ensure new messages are at the bottom */
}

.message {
    background-color: var(--card-dark);
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 5px;
    /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
    color: var(--text-white);
    font-size: 16px;
}

.message-enter {
    opacity: 0;
    transform: translateY(-20px);
}
.message-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}
.message-exit {
    opacity: 1;
}
.message-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.send-message-form {
    display: flex;
    flex-direction: column; /* Ensure input fields and button are in separate rows */
    /* padding: 10px; */
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: transparent;
    flex-shrink: 0;
    width: 280px; /* Ensure full width */
}

.input-field {
    width: 258px; /* Full width for input fields */
    margin-bottom: 8px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}


.input-field::placeholder {
    font-size: 16px;
    padding-left: 1px;
    color: #aaa;
}

.input-field.username-input {
    flex-grow: 0;
    width: 100%; /* Full width for username input */
    font-size: 16px;
}

.input-field.message-input {
    flex-grow: 1;
    width: 100%; /* Full width for message input */
    font-size: 16px;
}

.send-button {
    width: 60px; /* Full width for send button */
    height: 40px;
    padding: 0;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
}

.send-button:before {
    content: "➤";
    font-size: 16px;
}

@media (max-width: 950px) {
    .chat-container {
      display: none;
    }
  }